.idp {
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
    overflow:hidden;
    font-size: 0.95em;
    width: 100%;
}
.idp-box {
    margin: 5px;
    padding-left: 5px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    min-height: 95px;
    max-height: 95px;
    overflow:hidden;
}

.idp-wide {
    margin: 2px;
    padding: 3px;
    text-align: left;
    min-height: 70px;
    max-height: 70px;
}

.idp:hover {
    outline: none;
    border-color: #07426E;
    box-shadow: 0 0 10px #07426E;
    cursor: pointer;
}

.idp-title {
    font-size: 1.1em;
}

.idp-country {
    font-size: 0.9em;
}

.idp-country img {
    marging-left: 5px;
    margin-right: 5px;
}

.logo-container {
    margin: 0px;
    position: relative;
    text-align: center;
}

.logo-container-box {
    height: 79px; /* 95 - 2x5 - 2x3*/
}

.logo-container-wide {
    height: 49px;
}

.logo {
    min-width: 25%;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.previous-selected-idp {
    margin-bottom: 25px;
}

.grid-margin {
    margin-top: 10px;
    margin-bottom: 10px;
}

.idp-grid {
    margin-top: 20px;
    margin-bottom: 20px;
}