html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #000000;
  overflow: auto;
  font-family: "Source Sans Pro",Calibri,Arial,Helvetica,sans-serif;
  font-size: 14px;
}

#root {
  width: 100%;
  height: 100%;
  position:relative;
  background-color: #eee;
}

.clarin-logo {
  width: 119px;
}

#main {
  min-height: 95%;
  padding-top: 5em;
  margin-top: -5em;
  padding-bottom: 10em;
  margin-bottom: -8em; /*leave space for header footer*/
  background-color: #fff;
}

.title {
  margin-left: 5px;
}

.main {
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #eee;
}

.stats {
  font-size: 0.85em;
}

.justified {
  text-align: justify;
}

.text-small {
  font-size: 0.95em;
}

.text-left {
    text-align: left;
}

.text-center {
  text-align: center;
}

.error {
  color: #ff0000;
}

.panel-title {
  text-align: left;
}

#footer {
       margin-top: 0em;
       min-height: 10em;
       max-height: 10em;
       height: 10em;
       padding-top: 30px;
       border-top: 1px solid #d5d5d5;
       background-color: #eee;
       color: #777;
 }

.licenceText {
    font-style: italic;
    margin-left: 1em;
    padding-left: 1em;
    border: 1px dotted;
    margin-bottom: .5em;
}

/*
a {
  color: #0080aa;
  text-decoration: none;
}

a {
  background-color: transparent;
}



.about {
  text-align: left;
}

h1, .h1 {
  font-size: 36px;
}
h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #0080aa;
}
*/


.panel-no-horizonal-padding {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
}

.no-horizonal-padding {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
}

.minimal-padding {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
}

/* Temporary fix for header issue until this is included in the base style */
.navbar-nav>li>a {
    padding-top: 0px;
    padding-bottom: 0px;
}